import { render, staticRenderFns } from "./FrEventCard.vue?vue&type=template&id=f6524990&scoped=true&"
import script from "./FrEventCard.vue?vue&type=script&lang=js&"
export * from "./FrEventCard.vue?vue&type=script&lang=js&"
import style0 from "./FrEventCard.vue?vue&type=style&index=0&id=f6524990&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6524990",
  null
  
)

export default component.exports